<template>
  <div class="bg-white">    
    <SiteHeader /> 
    <main>
      <div class="bg-white">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:pt-16">
          <div class="px-6 pt-1 lg:col-span-6 lg:px-0 xl:col-span-6">
            <div class="pb-20 mx-auto max-w-2xl lg:mx-0">
              <h1 class="mt-4 pb-10 font-black text-pink-500 sm:mt-10 text-4xl">
                Stress-free gift giving
              </h1>
              <ul class="font-black tracking-widest">
                <li><CheckMark class="mr-2" />Easily Manage Group Gifts</li>
                <li class="mb-6 ml-0 sm:ml-6 text-gray-500">
                  <div class="hidden sm:block ">
                    - Track who's buying and who's contributing <br />
                    - See who you owe and who owes you <br />
                  </div>
                  <router-link to="/blog/stress-free-holidays-with-together-gift-it.html" class="text-pink-600 underline">
                    Learn More</router-link>
                </li>

                <li><CheckMark class="mr-2" />Public and Private Gift Lists</li>
                <li class="mb-6 ml-0 sm:ml-6 text-gray-500">
                  <div class="hidden sm:block ">
                    - Public gift lists: the group can see the list<br />
                    - Private gift lists: only you can see the list<br />
                  </div>
                  <router-link to="/blog/together-gift-it-gift-lists.html" class="text-pink-600 underline">
                    Learn More</router-link>
                </li>

                <li><CheckMark class="mr-2" />Together GiftBot AI Gift Assistant</li>
                <li class="mb-6 ml-0 sm:ml-6 text-gray-500">
                  <div class="hidden sm:block ">
                    - GiftBot works together with you to brainstrom gift ideas <br />
                    - GiftBot gives you full control over idea creation <br />
                  </div>
                  <router-link to="/blog/together-gift-bot.html" class="text-pink-600 underline">
                    Learn More</router-link>
                </li>
              </ul>
              <div class="mt-10 flex items-center gap-x-6">
                <LoginModal :api-url="api_url">
                  <button type="button" class="font-bold tracking-wide uppercase inline-flex bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50">
                    Get Started Now
                  </button>
                </LoginModal>
              </div>
            </div>
          </div>
          <div class="px-6 pt-1 pb-24 hidden lg:block lg:col-span-6 lg:px-0 xl:col-span-6">
            <img style="transform: rotate(-18deg);" class="pt-3" src="./home/TogetherGiftIt-MainHeroImage.jpg" alt="Beautifully wrapped giftbox with a bow" />
          </div>
        </div>

        <div class="hidden lg:flex pb-10">
          <div class="mx-auto">
            <ArrowCircleDownIcon class="h-15 w-10 flex-none text-pink-500" aria-hidden="true" />
          </div>
        </div>
      </div>

      <div class="bg-gray-100">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl lg:text-center mb-10">
            <h2 class="pt-10 text-base font-bold leading-7 text-black-700">Reduce Holiday Stress.</h2>
            <p class="mt-2 text-3xl font-bold tracking-tight text-pink-500 sm:text-4xl">
              Find out how Together Gift It works.
            </p>
            <p class="mt-6 text-lg leading-8 text-gray-600">
              We're all about making gift-giving easy for groups, so you can focus on the important stuff.
            </p>
          </div>
          <div class="mx-auto max-w-2xl lg:max-w-none">
            <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              <div v-for="feature in features" :key="feature.name" class="flex flex-col sm:items-center">
                  <dt class="flex items-center sm:justify-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                      <component :is="feature.icon" class="h-5 w-5 flex-none text-pink-500" aria-hidden="true" />
                      {{ feature.name }}
                  </dt>
                  <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p class="flex-auto">{{ feature.description }}</p>
                  </dd>
              </div>
            </dl>

            <div class="mt-10 pb-10 flex">
              <div class="mx-auto">
                <LoginModal :api-url="api_url">
                  <button type="button" class="font-bold tracking-wide uppercase inline-flex bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50">
                    Get Started Now
                  </button>
                </LoginModal>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white pt-10 pb-20">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto">
            <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              <div v-for="feature in secondFeatures" :key="feature.name">
                <dt class="text-base font-semibold leading-7 text-gray-900">
                  <p class="mt-2 text-2xl font-bold tracking-tight text-pink-500">
                    {{ feature.name }}
                  </p>                  
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <CookieAcknowledge />
    </main>
    <SiteFooter />
  </div>
</template>
<script>

import LoginModal from '@/components/modals/LoginModal.vue'
import CheckMark from '../components/CheckMark.vue'
import {
  InboxIcon,
  GiftIcon,
  ArrowCircleDownIcon,
  TrashIcon,
  UsersIcon,
  LightBulbIcon,
  CurrencyDollarIcon,
} from '@heroicons/vue/outline'

const features = [
  {
    name: 'Create a Gift Event',
    description: 'Create an event, and invite your friends and family to join. Our platform lets you manage everything in one place. Invite users to share their holiday wishlists.',
    icon: GiftIcon,
  },
  {
    name: 'Share Gift Ideas',
    description: 'No more group chats or hand-written lists filled with gift ideas that get lost in the shuffle. Our platform keeps everything organized, so everyone is on the same page.',
    icon: LightBulbIcon,
  },
  {
    name: 'Track Contributions',
    description: 'Things can get complicated. But with our platform, you can easily organize group gift purchases, and make sure everything stays on track, and no one gets left out.',
    icon: CurrencyDollarIcon,
  },
]

const secondFeatures = [
  {
    name: 'No more stress',
    description: 'Say goodbye to the stress of organizing gift-giving for groups. Our platform takes care of the logistics so you can focus on the fun stuff.',
    icon: InboxIcon,
  },
  {
    name: 'Communicate.',
    description: 'With shared gift ideas and contributions, everyone knows what’s going on. No more surprises or disappointments.',
    icon: UsersIcon,
  },
  {
    name: 'Stay organized.',
    description: 'Keep everything in one place with our platform. No more group chats, emails, or spreadsheets to manage.',
    icon: TrashIcon,
  },
  {
    name: 'Gift It together.',
    description: 'Our platform makes gift-giving for groups easy, fun, and stress-free. Who knew it could be so simple?',
    icon: TrashIcon,
  },
]

import CookieAcknowledge from '@/components/CookieAcknowledge.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  components: {
    LoginModal,
    CookieAcknowledge,
    CheckMark,
    ArrowCircleDownIcon,
    SiteHeader,
    SiteFooter,
  },
  data () {
    return {
      api_url: process.env.VUE_APP_API_URL.replace('api/', ''),
    }
  },
  setup() {
    return {
      features,
      secondFeatures,
    }
  },
}
</script>
